


























// CORE
import { Component, Vue, Prop } from 'vue-property-decorator'

// LIBRARIES
import { DateTime } from 'luxon'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'

// INTERFACES
import { IYearQuarterFormPart } from '@/store/types'

@Component({
  components: {
    CaptionCard,
  },
})
export default class QuartersFormPart extends Vue {
  @Prop({ default: null })
  readonly data!: number


  private form: IYearQuarterFormPart = {
    year: '',
    quarters: [],
  }

  private years: string[] = []

  private get isNotCompleted (): boolean {
    return (
      this.form.quarters.includes(this.currentQuarter.toString())
      && DateTime.now().year.toString() === this.form.year
    )
  }

  private get currentQuarter (): number {
    return Math.ceil(DateTime.now().month / 3)
  }

  private get quarters (): string[] {
    const all = ['1', '2', '3', '4']

    const index = all.indexOf(this.currentQuarter.toString())

    if (DateTime.now().year.toString() === this.form.year) {
      return all.slice(0, index + 1)
    } else {
      return all
    }
  }

  private mounted () {
    const years = []

    for (let i = 2004; i <= DateTime.now().year; i++) {
      years.push(i.toString())
    }

    this.years = years
  }

  private handleChangeYear (value: string) {
    if (DateTime.now().year.toString() === value) {
      this.form.quarters = this.quarters.filter(item => this.form.quarters.includes(item))
    }
  }
}
