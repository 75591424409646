














































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import QuartersFormPart from '@/components/forms/parts/QuartersFormPart.vue'

// INTERFACES
import { RequestTypeEnum } from '@/store/types'

// STORE
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    DefaultLayout,
    CharacterCard,
    ControlsRequestFormPart,
    RequestFormPartWrapper,
    QuartersFormPart,
  },
})
export default class ActOfReconciliationOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private get orderRequestParams () : { id: number, type: RequestTypeEnum } {
    const id = +this.$route.params.orderId
    const type = RequestTypeEnum.ACT_OF_RECONCILIATION

    return { id, type }
  }

  private get order (): any {
    return FleetOrderModule.order
  }

  private get quarters (): string {
    return this.order.quarter.join(', ')
  }

  private created () {
    FleetOrderModule.getOrder(this.orderRequestParams)
      .catch(() => {
        this.notifyError('Заявка не найдена')
        this.$router.push({ name: 'orders' })
      })
  }
}
